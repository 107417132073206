import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ConnexionComponent} from './connexion/connexion.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EnregistrementComponent} from './connexion/enregistrement/enregistrement.component';
import {ListGameComponent} from './list-game/list-game.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GamepageComponent} from './gamepage/gamepage.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatChipsModule} from "@angular/material/chips";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from "@angular/common/http";
import {AccueilComponent} from './accueil/accueil.component';
import {ProfilComponent} from './profil/profil.component';
import {FlexModule} from "@angular/flex-layout";
import {HttpResponseInterceptor} from './interceptors/http-response.interceptor';
import {DevenirPartenaireComponent} from './partenaire/devenir-partenaire/devenir-partenaire.component';
import {CreationComponent} from "./creation/creation.component";
import {MatListModule} from "@angular/material/list";
import {JeuElementComponent} from './partenaire/jeu-element/jeu-element.component';
import {CreerJeuPopupComponent} from './partenaire/dashboard/editeur/creer-jeu-popup/creer-jeu-popup.component';
import {MatDialogModule} from "@angular/material/dialog";
import {DashboardComponent} from './partenaire/dashboard/dashboard.component';
import {ConditionsPopupComponent} from './partenaire/jeu-element/conditions-popup/conditions-popup.component';
import {APP_BASE_HREF} from "@angular/common";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {UploadImageComponent} from './creation/upload-image/upload-image.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CookieConsentComponent} from './cookie-consent/cookie-consent.component';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatTooltipModule} from "@angular/material/tooltip";
import {ResetPasswordComponent} from './connexion/reset-password/reset-password.component';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ValidateAccountComponent} from './connexion/validate-account/validate-account.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatBadgeModule} from "@angular/material/badge";
import {FeedbackComponent} from './feedback/feedback.component';
import {GameManagerComponent} from './partenaire/game-manager/game-manager.component';
import {
  AchievementsManagementComponent
} from './partenaire/game-manager/achievements-management/achievements-management.component';
import {MatTableModule} from "@angular/material/table";
import {MainToolComponent} from "./devtools/main-tool/main-tool.component";
import {ApiModule} from "./swagger/api.module";
import {Configuration} from "./swagger/configuration";
import {BASE_PATH} from "./swagger/variables";
import {environment} from "../environments/environment";
import {TextResponseInterceptor} from "./interceptors/texte-response.interceptor";
import {ChoixPagePopupComponent} from './partenaire/jeu-element/choix-page-popup/choix-page-popup.component';
import {MultilangueComponent} from './creation/multilangue/multilangue.component';
import {
  CreerGamepageComponent
} from './partenaire/jeu-element/choix-page-popup/creer-gamepage/creer-gamepage.component';
import {OrganizationInvitationComponent} from './partenaire/organization-invitation/organization-invitation.component';
import {MembresComponent} from './partenaire/dashboard/membres/membres.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {AddMemberComponent} from './partenaire/dashboard/membres/add-member/add-member.component';
import {GamesComponent} from './partenaire/dashboard/games/games.component';
import {OrgGeneralComponent} from './partenaire/dashboard/organization/org-general/org-general.component';
import {OrganizationComponent} from './partenaire/dashboard/organization/organization.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {RightsManagementComponent} from './partenaire/game-manager/rights-management/rights-management.component';
import {FinancesComponent} from './partenaire/dashboard/organization/finances/finances.component';
import {GmGamePagesComponent} from './partenaire/game-manager/gm-game-pages/gm-game-pages.component';
import {
  GmGamePageElementComponent
} from './partenaire/game-manager/gm-game-pages/gm-game-page-element/gm-game-page-element.component';
import {
  AddMemberPopupComponent
} from './partenaire/game-manager/rights-management/add-member-popup/add-member-popup.component';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {BuildComponent} from './partenaire/game-manager/build/build.component';
import {DeleteMemberComponent} from './partenaire/dashboard/membres/delete-member/delete-member.component';
import {
  ModifyMemberRoleComponent
} from './partenaire/dashboard/membres/modify-member-role/modify-member-role.component';
import {IndiepassModalsComponent} from './services/indiepass-modals/indiepass-modals.component';
import {BuildPopupComponent} from './partenaire/game-manager/build/build-popup/build-popup.component';
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {GmGameComponent} from './partenaire/game-manager/gm-game/gm-game.component';
import {AboutComponent} from './about/about.component';
import {GameComponent} from "./list-game/game/game.component";
import {FiltresComponent} from "./list-game/filtres/filtres.component";
import {BuildImageUrlPipe} from './pipes/build-image-url.pipe';
import {CarrouselComponent} from './carrousel/carrousel.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {AchievementsComponent} from './gamepage/achievements/achievements.component';
import {GameDetailsComponent} from './gamepage/game-details/game-details.component';
import {DisableButtonDirective} from './directives/disable-button.directive';
import {ItemLibraryComponent} from './list-game/item-library/item-library.component';
import {CookieService} from "./cookie.service";
import { LanguageNamePipe } from './pipes/language-name.pipe';
import { FiltreNamePipe } from './pipes/filtre-name.pipe';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    EnregistrementComponent,
    ListGameComponent,
    GamepageComponent,
    AccueilComponent,
    ProfilComponent,
    DevenirPartenaireComponent,
    CreationComponent,
    JeuElementComponent,
    CreerJeuPopupComponent,
    DashboardComponent,
    ConditionsPopupComponent,
    UploadImageComponent,
    CookieConsentComponent,
    ResetPasswordComponent,
    SnackBarComponent,
    ValidateAccountComponent,
    FeedbackComponent,
    GameManagerComponent,
    AchievementsManagementComponent,
    MainToolComponent,
    ChoixPagePopupComponent,
    MultilangueComponent,
    MultilangueComponent,
    MultilangueComponent,
    CreerGamepageComponent,
    OrganizationInvitationComponent,
    MembresComponent,
    AddMemberComponent,
    GamesComponent,
    OrgGeneralComponent,
    OrganizationComponent,
    RightsManagementComponent,
    FinancesComponent,
    GmGamePagesComponent,
    GmGamePageElementComponent,
    AddMemberPopupComponent,
    BuildComponent,
    DeleteMemberComponent,
    ModifyMemberRoleComponent,
    IndiepassModalsComponent,
    BuildPopupComponent,
    GmGameComponent,
    AboutComponent,
    GameComponent,
    FiltresComponent,
    BuildImageUrlPipe,
    CarrouselComponent,
    AchievementsComponent,
    GameDetailsComponent,
    DisableButtonDirective,
    ItemLibraryComponent,
    LanguageNamePipe,
    FiltreNamePipe,

  ],
  imports: [
    ApiModule,
    MatSnackBarModule,
    InfiniteScrollModule,
    MatInputModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    MatGridListModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatCardModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDividerModule,
    MatChipsModule,
    FlexModule,
    MatListModule,
    FormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    DragDropModule,
    MatProgressSpinnerModule,
    BrowserModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTooltipModule,
    MatTabsModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ClipboardModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSidenavModule,
    // !environment.production ? DevtoolsModule : [],
  ],
  providers: [
    TranslatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: TextResponseInterceptor, multi: true},
    {provide: BASE_PATH, useValue: environment.basePath},
    {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: 'googleTagManagerId', useValue: environment.googleTagManagerId},
    {provide: 'googleTagManagerIdCookie', useValue: environment.googleTagManagerIdCookie},
    {
      provide: LOCALE_ID,
      useFactory: () => {
        const cookie: CookieService = new CookieService();
        let language = cookie.getCookie("lang")

        if (!language) {
          // @ts-ignore
          language = navigator.language || navigator['userLanguage'];
        }
        // Retourner la locale correspondante (par exemple, "fr" pour le français)
        return language!.startsWith('fr') ? 'fr' :
          language!.startsWith('es') ? 'es' : 'en'; // Défaut à l'anglais
      }
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
